import Button from 'components/button'
import LegacyButton from 'components/button.legacy'
import * as _ from 'modules/util'
import { forwardRef } from 'react'
import { useFormContext, useFormState } from 'react-hook-form'

const SubmitButton = forwardRef(
  ({ disabled: disabledIn, isLoading, legacyStyle, ...propsIn }, ref) => {
    const { id } = useFormContext()
    const { disabled, errors, isSubmitting, submitCount } = useFormState()
    const hasErrors = !!Object.keys(errors).length
    const ButtonComponent = legacyStyle ? LegacyButton : Button
    const props = {
      action: _.when(legacyStyle, true),
      'aria-busy': _.when(isLoading || isSubmitting, true),
      color: 'pink-fill',
      isLoading: isLoading || isSubmitting,
      disabled: disabled || disabledIn || isSubmitting || (submitCount && hasErrors),
      type: 'submit',
      ref,
      ...propsIn,
    }

    return <ButtonComponent {...props} form={id} />
  }
)

export default SubmitButton
